import React, { useEffect, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Context } from '../../../store';
import {Row, Col, Statistic, Card, Tabs, Button} from 'antd';
import { singleCurrencyAllCandlestickFilter, singleCurrencyAllPositionFilter } from '../../../libs/analysis';
//import {positionTypes} from '../../../libs/enums';
import CurrencyHistoricalTable from '../../../components/CurrencyHistoricalTable';
import CurrencyInfo from '../../../components/CurrencyInfo';
import CurrencyNews from '../../../components/CurrencyNews';
import './style.scss';
//import CurrencyAllPositionTable from '../../../components/CurrencyAllPositionTable';
import AnalysisLoading from '../../../components/AnalysisLoading';
import TradingViewWidget, { Themes } from 'react-tradingview-widget';

const {TabPane} = Tabs;

const SpotDetails = () => {
    let {id} = useParams();

    const {state } = useContext(Context);
    const [currencyInfo, setCurrenyInfo] = useState({});
    const [currencyDeals, setCurrencyDeals] = useState([]);
    const [totalValues, setTotalValues] = useState({
        totalPosProcess : 0,
        totalNegProcess : 0,
        totalProfits : 0,
        totalLoss: 0,
        mostProfit: 0,
        mostLoss: 0
    });
    //const [currencyAllPositions, setCurrencyAllPositions] = useState([]);
    //const [activePosition, setActivePosition] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const cInfo = state.symbols.find(x => x.baseAsset === id.toUpperCase());
        //console.log(cInfo);
        setCurrenyInfo(cInfo);
        //const activePos = positionTypes.find(x => x.name === cInfo.positionModel)
        //setActivePosition(activePos);
        //const cKlines = state.kLinesList[cInfo.symbol];
        //console.log(cKlines);

        const activeCurrencyDeals = [...state.oldPositions, ...state.openPositions].filter(p => p.symbol === cInfo.symbol);
        setCurrencyDeals(activeCurrencyDeals);
        /*
        singleCurrencyAllCandlestickFilter(cInfo.symbol, cKlines, 200, state.analysisModel).then(data => {
            //console.log('CURRENCY DEALS', data);
            setCurrencyDeals(data);
        });
        */

    }, [state.symbols, id, state.oldPositions, state.openPositions]);


    useEffect(() => {
        if(currencyDeals.length > 0) {
            let posArray = [];
            let negArray = [];
            let notrArray = [];
            let totalProfits;
            let totalLoss;
            let mostProfit;
            let mostLoss;

            if(state.enabledSimulator || state.analysisModel === "depthHill"|| state.analysisModel === "explosionLoose") {
                posArray = currencyDeals.filter(x => x.pnl > 0.1);
                negArray = currencyDeals.filter(x => x.pnl < 0);
                notrArray = currencyDeals.filter(x => x.pnl <= 0.1 && x.pnl >= 0);
                totalProfits = posArray.length > 0 ? posArray.length === 1 ? posArray[0].pnl : posArray.map(x => x.pnl).reduce((a, b) => a + b).toFixed(2) : 0;
                totalLoss = negArray.length > 0 ? negArray.length === 1 ? negArray[0].pnl : negArray.map(x => x.pnl).reduce((a, b) => a + b).toFixed(2) : 0;
                mostProfit = posArray.length > 0 ? posArray.length === 1 ? posArray[0].pnl : posArray.map(x => x.pnl).reduce((a, b) => b > a ? b : a).toFixed(2) : 0;
                mostLoss = negArray.length > 0 ? negArray.length === 1 ? negArray[0].pnl : negArray.map(x => x.pnl).reduce((a, b) => b > a ? a : b).toFixed(2) : 0;
            }
            else {
                posArray = currencyDeals.filter(x => x.mostProfit > 1);
                negArray = currencyDeals.filter(x => x.mostProfit <= 1);
                totalProfits = posArray.length > 0 ? posArray.length === 1 ? posArray[0].mostProfit : posArray.map(x => x.mostProfit).reduce((a, b) => a + b).toFixed(2) : 0;
                totalLoss = negArray.length > 0 ? negArray.length === 1 ? negArray[0].pnl : negArray.map(x => x.pnl).reduce((a, b) => a + b).toFixed(2) : 0;
                mostProfit = posArray.length > 0 ? posArray.length === 1 ? posArray[0].mostProfit : posArray.map(x => x.mostProfit).reduce((a, b) => b > a ? b : a).toFixed(2) : 0;
                mostLoss = negArray.length > 0 ? negArray.length === 1 ? negArray[0].pnl : negArray.map(x => x.pnl).reduce((a, b) => b > a ? a : b).toFixed(2) : 0;
            }

            setTotalValues({
                totalPosProcess: posArray.length,
                totalNegProcess: negArray.length,
                totalNotrProcess: notrArray.length,
                totalProfits: totalProfits,
                totalLoss: totalLoss,
                pnlRatio: (Number(totalProfits) + Number(totalLoss)).toFixed(2),          
                mostProfit: mostProfit,
                mostLoss: mostLoss
            });
        } 

    }, [currencyDeals, state.enabledSimulator, state.analysisModel]);

    /*
    const tabOnChange = (activeKey) => { 
        if(activeKey === "2" && currencyAllPositions.length === 0) {
            // burası yenilenmiyor fonksiyona ayır ve kLinesList değişirse buda yenilensin
            setIsLoading(true);
            setTimeout(() => {
                singleCurrencyAllPositionFilter(currencyInfo.symbol, state.kLinesList[currencyInfo.symbol]).then((data) => {
                    //console.log('son 24 saattir aktif devam eden fırsatları göster');
                    //console.log('LAST 24 HOURS DEALS', data);
                    console.log(data); 
                    setCurrencyAllPositions(data);
                    setIsLoading(false);
                });
            }, 1000);
        }
    }

    const getSelectedPositionType = (pos) => {
        singleCurrencyAllCandlestickFilter(currencyInfo.symbol, state.kLinesList[currencyInfo.symbol], pos).then(data => {
            //console.log('CURRENCY DEALS', data);
            setCurrencyDeals(data);
            setActivePosition(pos);
        });
    }

    const positionTypeButtons = positionTypes.map((pos, i) => {
        return <Button key={i} type='default' className={activePosition.name === pos.name ? "active" : ""} onClick={() => getSelectedPositionType(pos)}>
            {pos.name}
        </Button>
    }); 
    */
    return (
        <>
            {isLoading && <AnalysisLoading current={0} total={0} spin={true} />}
            <div className='futures-detail container'>
                <Row gutter="16">
                    <Col span={8}>
                        <CurrencyInfo marketType="SPOT" currency={currencyInfo} />
                    </Col>
                    <Col span={16}>
                        {/*<CurrencyNews symbolName={currencyInfo.name} />*/}
                        <div className="currency-graphic">
                            <div className='graphic-container'>
                                <TradingViewWidget
                                    symbol={`BINANCE:${currencyInfo.symbol}`}
                                    interval="15"
                                    timezone="Europe/Istanbul"
                                    hide_side_toolbar={false}
                                    allow_symbol_change={false}
                                    withdateranges={true}
                                    theme={Themes.Light}
                                    locale="tr"
                                    autosize
                                    show_popup_button={true}
                                    popup_width="1000"
                                    popup_height="650"
                                    studies={[
                                        "BB@tv-basicstudies",
                                        "MAExp@tv-basicstudies",
                                        "RSI@tv-basicstudies"
                                    ]}
                                />
                            </div>
                        </div>
                    </Col>
                    <Col span={24} style={{marginBottom: 20}}>
                        <Row gutter={16}>
                            <Col span={8}>
                                <Card title="Total Position">
                                    <Statistic 
                                        title="Profit"
                                        value={totalValues.totalPosProcess}
                                        precision={0}
                                        valueStyle={{ color: '#3f8600' }}
                                        prefix={''}
                                        suffix="Process"
                                        />
                                    <Statistic
                                        title="Loss"
                                        value={totalValues.totalNegProcess}
                                        precision={0}
                                        valueStyle={{ color: '#cf1322' }}
                                        prefix={''}
                                        suffix="Process"
                                        />
                                    <Statistic
                                        title="Notr"
                                        value={totalValues.totalNotrProcess}
                                        precision={0}
                                        valueStyle={{ color: '#000' }}
                                        prefix={''}
                                        suffix="Process"
                                        />
                                </Card>
                            </Col>
                            <Col span={8}>
                                <Card title="Total Profit / Loss">
                                    <span className='pnl-ratio'>
                                        {totalValues.pnlRatio} %
                                    </span>
                                    <Statistic 
                                        title="Profit"
                                        value={totalValues.totalProfits}
                                        precision={2}
                                        valueStyle={{ color: '#3f8600' }}
                                        prefix={''}
                                        suffix="%"
                                        />
                                    <Statistic
                                        title="Loss"
                                        value={totalValues.totalLoss}
                                        precision={2}
                                        valueStyle={{ color: '#cf1322' }}
                                        prefix={''} 
                                        suffix="%"
                                        />
                                </Card>
                            </Col>
                            <Col span={8}>
                                <Card title="Most Profit / Loss">
                                    <Statistic 
                                        title="Profit"
                                        value={totalValues.mostProfit}
                                        precision={2}
                                        valueStyle={{ color: '#3f8600' }}
                                        prefix={''}
                                        suffix="%"
                                        />
                                    <Statistic
                                        title="Loss"
                                        value={totalValues.mostLoss}
                                        precision={2}
                                        valueStyle={{ color: '#cf1322' }}
                                        prefix={''}
                                        suffix="%"
                                        />
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={24}>
                        <div className="deals">
                            <Tabs defaultActiveKey="1" type="card" size={"large"}>
                                <TabPane tab={`Current Deals ( ${currencyDeals.length} )`} key="1">
                                    {/*
                                    <div className="position-buttons">
                                        {positionTypeButtons}
                                    </div>                                    
                                    */}
                                    {currencyDeals && <CurrencyHistoricalTable data={currencyDeals} />}
                                </TabPane>
                                {/*
                                <TabPane tab="All Positions Pair Type" key="2">
                                    {currencyAllPositions && <CurrencyAllPositionTable data={currencyAllPositions} />}
                                </TabPane>                                
                                */}
                            </Tabs>
                        </div>                     
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default SpotDetails;

/**
                 <Col span={24}>
                    {currencyDeals && <CurrencyHistoricalTable data={currencyDeals} />}
                </Col>
 */