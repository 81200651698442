import React, {useContext, useState, useEffect} from 'react';
import './style.scss';
import {Table, Tag, Col, Row, Statistic, Card, Button} from 'antd';
import {epochToDate, getUSDollar} from '../../libs/utility';
import {Context} from '../../store';
import { UpCircleOutlined, DownCircleOutlined, CloseCircleOutlined} from '@ant-design/icons';
import {Link} from 'react-router-dom';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import {ReactComponent as TwLogo} from '../../assets/icons/tw-logo2.svg';

const AllDailyProfitLossTable = ({data}) => {
    const {state} = useContext(Context);
    const [totalValues, setTotalValues] = useState({
        totalPosProcess : 0,
        totalNegProcess : 0,
        totalProfits : 0,
        totalLoss: 0,
        mostProfit: 0,
        mostLoss: 0,
        totalRealProfits: 0,
        totalRealLoss: 0,
    });

    useEffect(() => {
        if(data.length > 0) {

            let posArray = [];
            let negArray = [];
            let notrArray = [];
            let totalProfits;
            let totalLoss;
            let mostProfit;
            let mostLoss;
            let totalRealProfits;
            let totalRealLoss;

            if(state.enabledSimulator || state.analysisOptions.model === "depthHill"|| state.analysisOptions.model === "explosionLoose") {
                posArray = data.filter(x => x.pnl > 0.1);
                negArray = data.filter(x => x.pnl < 0);
                notrArray = data.filter(x => x.pnl <= 0.1 && x.pnl >= 0);
                totalProfits = posArray.length > 0 ? posArray.length === 1 ? posArray[0].pnl : posArray.map(x => x.pnl).reduce((a, b) => a + b).toFixed(2) : 0;
                totalLoss = negArray.length > 0 ? negArray.length === 1 ? negArray[0].pnl : negArray.map(x => x.pnl).reduce((a, b) => a + b).toFixed(2) : 0;
                mostProfit = posArray.length > 0 ? posArray.length === 1 ? posArray[0].pnl : posArray.map(x => x.pnl).reduce((a, b) => b > a ? b : a).toFixed(2) : 0;
                mostLoss = negArray.length > 0 ? negArray.length === 1 ? negArray[0].pnl : negArray.map(x => x.pnl).reduce((a, b) => b > a ? a : b).toFixed(2) : 0;
                totalRealProfits = posArray.length > 0 ? posArray.length === 1 ? posArray[0].realPnl : posArray.map(x => x.realPnL).reduce((a, b) => a + b).toFixed(2) : 0;
                totalRealLoss = negArray.length > 0 ? negArray.length === 1 ? negArray[0].realPnL : negArray.map(x => x.realPnL).reduce((a, b) => a + b).toFixed(2) : 0;
            }
            if(state.analysisOptions.model === "rsiDivergence" || state.analysisOptions.model === "TC" || state.analysisOptions.model === "SD") {
                posArray = data.filter(x => x.pnl > 0);
                negArray = data.filter(x => x.pnl < 0);
                notrArray = data.filter(x => x.pnl === 0);
                totalProfits = posArray.length > 0 ? posArray.length === 1 ? posArray[0].pnl : posArray.map(x => x.pnl).reduce((a, b) => a + b).toFixed(2) : 0;
                totalLoss = negArray.length > 0 ? negArray.length === 1 ? negArray[0].pnl : negArray.map(x => x.pnl).reduce((a, b) => a + b).toFixed(2) : 0;
                mostProfit = posArray.length > 0 ? posArray.length === 1 ? posArray[0].pnl : posArray.map(x => x.pnl).reduce((a, b) => b > a ? b : a).toFixed(2) : 0;
                mostLoss = negArray.length > 0 ? negArray.length === 1 ? negArray[0].pnl : negArray.map(x => x.pnl).reduce((a, b) => b > a ? a : b).toFixed(2) : 0;
                totalRealProfits = posArray.length > 0 ? posArray.length === 1 ? posArray[0].realPnl : posArray.map(x => x.realPnL).reduce((a, b) => a + b).toFixed(2) : 0;
                totalRealLoss = negArray.length > 0 ? negArray.length === 1 ? negArray[0].realPnL : negArray.map(x => x.realPnL).reduce((a, b) => a + b).toFixed(2) : 0;
            }
            else {
                posArray = data.filter(x => x.mostProfit > 1);
                negArray = data.filter(x => x.mostProfit <= 1);
                totalProfits = posArray.length > 0 ? posArray.length === 1 ? posArray[0].mostProfit : posArray.map(x => x.mostProfit).reduce((a, b) => a + b).toFixed(2) : 0;
                totalLoss = negArray.length > 0 ? negArray.length === 1 ? negArray[0].pnl : negArray.map(x => x.pnl).reduce((a, b) => a + b).toFixed(2) : 0;
                mostProfit = posArray.length > 0 ? posArray.length === 1 ? posArray[0].mostProfit : posArray.map(x => x.mostProfit).reduce((a, b) => b > a ? b : a).toFixed(2) : 0;
                mostLoss = negArray.length > 0 ? negArray.length === 1 ? negArray[0].pnl : negArray.map(x => x.pnl).reduce((a, b) => b > a ? a : b).toFixed(2) : 0;
                totalRealProfits = posArray.length > 0 ? posArray.length === 1 ? posArray[0].realPnl : posArray.map(x => x.realPnL).reduce((a, b) => a + b).toFixed(2) : 0;
                totalRealLoss = negArray.length > 0 ? negArray.length === 1 ? negArray[0].realPnL : negArray.map(x => x.realPnL).reduce((a, b) => a + b).toFixed(2) : 0;
            }

            setTotalValues({
                totalPosProcess: posArray.length,
                totalNegProcess: negArray.length,
                totalNotrProcess: notrArray.length,
                totalProfits: totalProfits,
                totalLoss: totalLoss,
                pnlRatio: (Number(totalProfits) + Number(totalLoss)).toFixed(2),          
                mostProfit: mostProfit,
                mostLoss: mostLoss,
                totalRealProfits: totalRealProfits,
                totalRealLoss: totalRealLoss,
                realPnlRatio: (Number(totalRealProfits) + Number(totalRealLoss)).toFixed(2), 
            });
        }
        else {
            setTotalValues({
                totalPosProcess : 0,
                totalNegProcess : 0,
                totalProfits : 0,
                totalLoss: 0,
                mostProfit: 0,
                mostLoss: 0,
                totalRealProfits: 0,
                totalRealLoss: 0,
            });
        }

    }, [data, state.enabledSimulator, state.analysisModel]);

    const linkPrefix = state.marketType === "FUTURES" ? "futures" : "spot";

    const columns = [
        {
            title: 'Symbol',
            dataIndex: 'symbol',
            key: 'symbol',
            render: (symbol, record) => symbolBuilder(symbol, record),
            sorter: (a, b) => ('' + a.symbol).localeCompare(b.symbol),
            width: 160
        },
        {
          title: 'Status',
          dataIndex: 'status',
          key: 'status',
          render: (text, record) => tagBuilder(text, record),
          filters: [
              {
                  text: 'LONG',
                  value: 'OPEN LONG'
              },
              {
                  text: 'SHORT',
                  value: 'OPEN SHORT'
              }
          ],
          onFilter: (value, record) => record.status.indexOf(value) === 0
        },
        {
            title: 'Pos Type',
            dataIndex: 'positionType',
            key: 'positionType',
            render: (text, record) => posBuilder(record),
            width: 170
        },
        {
            title: 'Strength',
            dataIndex: 'point',
            key: 'point',
            className: 'deal-strength',
            render: (text, record) => strengthProgressBar(text),
        },
        {
          title: 'Open Price',
          dataIndex: 'openPrice',
          key: 'openPrice',
          render: price => getUSDollar(price, 6)
        },
        {
            title: 'Close Price',
            dataIndex: 'closePrice',
            key: 'closePrice',
            render: price => getUSDollar(price, 6)
          },
        {
          title: 'Open Date',
          key: 'openDate',
          dataIndex: 'openDate',
          render: date => epochToDate(date).toLocaleString(),
        },
        {
            title: 'Close Date',
            key: 'closeDate',
            dataIndex: 'closeDate',
            render: date => epochToDate(date).toLocaleString(),
          },
        {
            title: 'Pass Time',
            key: 'time',
            dataIndex: 'time',
            render: (time, record) => `${time}`,
            sorter: (a, b) => a.time - b.time,
        },
        {
            title: 'Reverse (C)',
            key: 'closeReverse',
            dataIndex: 'closeReverse',
            render: (closeReverse, record) => reverseBuilder(closeReverse, record.closeReverseIndex),
            sorter: (a, b) => a.closeReverse - b.closeReverse,
        },
        {
              title: 'Reverse (M)',
              key: 'maxReverse',
              dataIndex: 'maxReverse',
              render: (maxReverse, record) => reverseBuilder(maxReverse, record.maxReverseIndex),
              sorter: (a, b) => a.maxReverse - b.maxReverse,
        },
        {
            title: 'First Reverse',
            key: 'firstReverse',
            dataIndex: 'firstReverse',
            render: (firstReverse, record) => reverseBuilder(firstReverse, 1),
            sorter: (a, b) => a.firstReverse - b.firstReverse,
        },
        {
            title: 'High Profit',
            key: 'highProfit',
            dataIndex: 'highProfit',
            render: (highProfit, record) => reverseBuilder(highProfit, record.highPriceIndex),
            sorter: (a, b) => a.highProfit - b.highProfit,
        },
        {
            title: 'Most Profit',
            key: 'mostProfit',
            dataIndex: 'mostProfit',
            render: (mostProfit, record) => reverseBuilder(mostProfit, record.topNeedleIndex),
            sorter: (a, b) => a.mostProfit - b.mostProfit,
        },
        {
            title: 'Profit/Loss',
            key: 'pnl',
            dataIndex: 'pnl',
            render: (pnl, record) => `${pnl} % (${(getUSDollar(record.realPnL))})`,
            sorter: (a, b) => a.pnl - b.pnl,
        }
    ];

    const strengthProgressBar = (value) => {
        let pathColor;
        switch (true) {
            case (value < 17):
                pathColor = '#ff0d0d';
                break;
            case (value >= 17 && value < 34):
                pathColor = '#ff4e11';
                break;
            case (value >= 34 && value < 50):
                pathColor = '#ff8e15';
                break;
            case (value >= 50 && value < 67):
                pathColor = '#fab733';
                break; 
            case (value >= 67 && value < 84):
                pathColor = '#acb334';
                break;
            case (value >= 84):
                pathColor = '#69b34c';
                break;
                default:
                break;
        }
        return <div style={{width:48, height:48}}>
            <CircularProgressbar 
                value={value} 
                text={`${value}%`} 
                strokeWidth={12}
                styles={buildStyles({
                    // Rotation of path and trail, in number of turns (0-1)
                    rotation: 0.25,
                
                    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                    strokeLinecap: 'butt',
                
                    // Text size
                    textSize: '24px',
                
                    // How long animation takes to go from one percentage to another, in seconds
                    pathTransitionDuration: 0.5,
                
                    // Can specify path transition in more detail, or remove it entirely
                    // pathTransition: 'none',
                
                    // Colors
                    pathColor: pathColor,
                    textColor: '#333',
                    trailColor: '#d6d6d6',
                    backgroundColor: '#3e98c7',
                  })} />
        </div>
    }

    const openUrl = (symbol) => {
        const link = state.marketType === "FUTURES" ? symbol.symbol + '.P' : symbol.symbol;
        window.open(`https://www.tradingview.com/chart/fu2csY4t/?symbol=${symbol.market.toUpperCase()}%3A${link}`, '_blank');
    }

    const symbolBuilder = (symbol, record) => {
        const sym = state.symbols.find(x => x.symbol === symbol);
        const imageName = sym.iconUrl; // state.marketType === "FUTURES" ? sym?.baseAsset?.toLowerCase() + '.svg' : sym.icon;
        return <div className="symbol">
            <Link to={`/currencies/details/${symbol.replace('USDT', '').toLowerCase()}`} state={{uniqId: sym.id}}>
                <img src={`${process.env.PUBLIC_URL}/images/crypto-logo/${imageName}`} alt="" />
                <span className="name"><strong>{sym.baseAsset}</strong></span>
                {/*
                <span className={`risk ${record.riskLevel}`}>
                    {record.riskLevel === "star" && <StarFilled style={{color: '#17a5f9', fontSize: 16}}/>}
                </span>
                */}
            </Link>
            <Button type="primary" shape="circle" className='btn-black' style={{ marginLeft: 10}} icon={<span role="img" aria-label="tw" className="anticon anticon-history"><TwLogo /></span>} size={"small"} onClick={() => openUrl(sym)} />
        </div>
    }

    const tagBuilder = (text, record) => {
        let color = null;
        let icon = null;
        switch (text) {
            case 'LONG':
                color = "#87d068";
                icon = <UpCircleOutlined />;
                break;
            case 'SHORT':
                color = "#f50";
                icon = <DownCircleOutlined />;
                break;
            case 'EMPTY':
                color = "#000";
                icon = <CloseCircleOutlined />;
                break;
            default:
                color = "black";
                break;
        }
        return <div className='deals-tag'>
                    <Tag icon={icon} color={color}>{text.toUpperCase()}</Tag>
               </div>
    }

    const posBuilder = (record) => {
        return <div className="pos-type">
            <Tag color={"#87d068"}>{record.posType}</Tag>
            <Tag color={"#3b5081"}>{record.closeType}</Tag>
        </div>
    }

    const reverseBuilder = (reverse, index) => {
        return <div className="reverse">
            <span className="reverse-value">{reverse} %</span>
            {index > 0 && <span className="reverse-index">{index}</span>}
        </div>
    }

    const rowClassNameBuilder = (record, index) => {
        if (state.analysisOptions.model === "rsiDivergence" || state.analysisOptions.model === "TC" || state.analysisOptions.model === "SD") {
            if(record.pnl > 0) {
                return "soft-green";
            }
            else if (record.pnl === 0) {
                return "soft-gray";
            }
            else {
                return "soft-red";
            }
        }
        else if(!state.enabledSimulator && (state.analysisOptions.model !== "depthHill" && state.analysisOptions.model !== "explosionLoose")) {
            return record.mostProfit > 1 ? "soft-green" : "soft-red";
        }
        else {
            if(record.pnl > 0.1) {
                return "soft-green";
            }
            else if (record.pnl >= 0 && record.pnl <= 0.1) {
                return "soft-gray";
            }
            else {
                return "soft-red";
            }
        }
    }

    return (
        <div className='all-daily-profit-loss-table'>
            <Row gutter={16} style={{marginBottom: 20}}>
                <Col span={state.analysisOptions.entryAmount ? 6 : 8}>
                    <Card title="Total Position">
                        <Statistic 
                            title="Profit"
                            value={totalValues.totalPosProcess}
                            precision={0}
                            valueStyle={{ color: '#3f8600' }}
                            prefix={''}
                            suffix="Process"
                            />
                        <Statistic
                            title="Loss"
                            value={totalValues.totalNegProcess}
                            precision={0}
                            valueStyle={{ color: '#cf1322' }}
                            prefix={''}
                            suffix="Process"
                            />
                        <Statistic
                            title="Notr"
                            value={totalValues.totalNotrProcess}
                            precision={0}
                            valueStyle={{ color: '#000' }}
                            prefix={''}
                            suffix="Process"
                            />
                    </Card>
                </Col>
                <Col span={state.analysisOptions.entryAmount ? 6 : 8}>
                    <Card title="Total Profit / Loss">
                        <span className='pnl-ratio'>
                            {totalValues.pnlRatio} %
                        </span>
                        <Statistic 
                            title="Profit"
                            value={totalValues.totalProfits}
                            precision={2}
                            valueStyle={{ color: '#3f8600' }}
                            prefix={''}
                            suffix="%"
                            />
                        <Statistic
                            title="Loss"
                            value={totalValues.totalLoss}
                            precision={2}
                            valueStyle={{ color: '#cf1322' }}
                            prefix={''} 
                            suffix="%"
                            />
                    </Card>
                </Col>
                {state.analysisOptions.entryAmount > 0 && 
                    <Col span={6}>
                        <Card title="Real Total Profit / Loss">
                            <span className='pnl-ratio'>
                                {getUSDollar(Number(totalValues.realPnlRatio))}
                            </span>
                            <Statistic 
                                title="Real Profit"
                                value={getUSDollar(totalValues.totalRealProfits)}
                                precision={2}
                                valueStyle={{ color: '#3f8600' }}
                                prefix={''}
                                suffix=""
                                />
                            <Statistic
                                title="Real Loss"
                                value={getUSDollar(totalValues.totalRealLoss)}
                                precision={2}
                                valueStyle={{ color: '#cf1322' }}
                                prefix={''} 
                                suffix=""
                                />
                        </Card>
                    </Col>
                }

                <Col span={state.analysisOptions.entryAmount ? 6 : 8}>
                    <Card title="Most Profit / Loss">
                        <Statistic 
                            title="Profit"
                            value={totalValues.mostProfit}
                            precision={2}
                            valueStyle={{ color: '#3f8600' }}
                            prefix={''}
                            suffix="%"
                            />
                        <Statistic
                            title="Loss"
                            value={totalValues.mostLoss}
                            precision={2}
                            valueStyle={{ color: '#cf1322' }}
                            prefix={''}
                            suffix="%"
                            />
                    </Card>
                </Col>
            </Row>
            <Table columns={columns} dataSource={data} bordered pagination={false} rowClassName={rowClassNameBuilder} />
        </div>
    );
};


export default AllDailyProfitLossTable;