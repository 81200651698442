import React, { useEffect, useContext, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Context } from '../../../store';
import {Row, Col, Statistic, Card, Tabs} from 'antd';
import CurrencyHistoricalTable from '../../../components/CurrencyHistoricalTable';
import CurrencyInfo from '../../../components/CurrencyInfo';
import './style.scss';
import AnalysisLoading from '../../../components/AnalysisLoading';
import TradingViewWidgetEmbed from '../../../components/TradingViewWidget';

const {TabPane} = Tabs;

const Details = () => {
    let {id} = useParams();
    const location = useLocation();

    const {state } = useContext(Context);
    const [currencyInfo, setCurrenyInfo] = useState({});
    const [currencyDeals, setCurrencyDeals] = useState([]);
    const [totalValues, setTotalValues] = useState({
        totalPosProcess : 0,
        totalNegProcess : 0,
        totalProfits : 0,
        totalLoss: 0,
        mostProfit: 0,
        mostLoss: 0
    });
    const [isLoading, setIsLoading] = useState(false);

    console.log('use location', location);

    useEffect(() => {
        const cInfo = state.firebaseSymbols.find(x => x.id === location.state.uniqId);
        setCurrenyInfo(cInfo);
        const activeCurrencyDeals = [...state.oldPositions, ...state.openPositions].filter(p => p.symbol === cInfo.symbol).sort((a, b) => b.openDate - a.openDate);
        setCurrencyDeals(activeCurrencyDeals);
    }, [state.symbols, id, state.oldPositions, state.openPositions]);


    useEffect(() => {
        if(currencyDeals.length > 0) {
            let posArray = [];
            let negArray = [];
            let notrArray = [];
            let totalProfits;
            let totalLoss;
            let mostProfit;
            let mostLoss;
            console.log(state.analysisOptions.model);
            if(state.analysisOptions.model === "TC" || state.analysisOptions.model === "SD") {
                posArray = currencyDeals.filter(x => x.pnl > 0);
                negArray = currencyDeals.filter(x => x.pnl < 0);
                notrArray = currencyDeals.filter(x => x.pnl === 0);
                totalProfits = posArray.length > 0 ? posArray.length === 1 ? posArray[0].pnl : posArray.map(x => x.pnl).reduce((a, b) => a + b).toFixed(2) : 0;
                totalLoss = negArray.length > 0 ? negArray.length === 1 ? negArray[0].pnl : negArray.map(x => x.pnl).reduce((a, b) => a + b).toFixed(2) : 0;
                mostProfit = posArray.length > 0 ? posArray.length === 1 ? posArray[0].pnl : posArray.map(x => x.pnl).reduce((a, b) => b > a ? b : a).toFixed(2) : 0;
                mostLoss = negArray.length > 0 ? negArray.length === 1 ? negArray[0].pnl : negArray.map(x => x.pnl).reduce((a, b) => b > a ? a : b).toFixed(2) : 0;
            }
            else if(state.enabledSimulator || state.analysisOptions.model === "depthHill" || state.analysisOptions.model === "explosionLoose") {
                posArray = currencyDeals.filter(x => x.pnl > 0.1);
                negArray = currencyDeals.filter(x => x.pnl < 0);
                notrArray = currencyDeals.filter(x => x.pnl <= 0.1 && x.pnl >= 0);
                totalProfits = posArray.length > 0 ? posArray.length === 1 ? posArray[0].pnl : posArray.map(x => x.pnl).reduce((a, b) => a + b).toFixed(2) : 0;
                totalLoss = negArray.length > 0 ? negArray.length === 1 ? negArray[0].pnl : negArray.map(x => x.pnl).reduce((a, b) => a + b).toFixed(2) : 0;
                mostProfit = posArray.length > 0 ? posArray.length === 1 ? posArray[0].pnl : posArray.map(x => x.pnl).reduce((a, b) => b > a ? b : a).toFixed(2) : 0;
                mostLoss = negArray.length > 0 ? negArray.length === 1 ? negArray[0].pnl : negArray.map(x => x.pnl).reduce((a, b) => b > a ? a : b).toFixed(2) : 0;
            }
            else {
                posArray = currencyDeals.filter(x => x.mostProfit > 1);
                negArray = currencyDeals.filter(x => x.mostProfit <= 1);
                totalProfits = posArray.length > 0 ? posArray.length === 1 ? posArray[0].mostProfit : posArray.map(x => x.mostProfit).reduce((a, b) => a + b).toFixed(2) : 0;
                totalLoss = negArray.length > 0 ? negArray.length === 1 ? negArray[0].pnl : negArray.map(x => x.pnl).reduce((a, b) => a + b).toFixed(2) : 0;
                mostProfit = posArray.length > 0 ? posArray.length === 1 ? posArray[0].mostProfit : posArray.map(x => x.mostProfit).reduce((a, b) => b > a ? b : a).toFixed(2) : 0;
                mostLoss = negArray.length > 0 ? negArray.length === 1 ? negArray[0].pnl : negArray.map(x => x.pnl).reduce((a, b) => b > a ? a : b).toFixed(2) : 0;
            }

            setTotalValues({
                totalPosProcess: posArray.length,
                totalNegProcess: negArray.length,
                totalNotrProcess: notrArray.length,
                totalProfits: totalProfits,
                totalLoss: totalLoss,
                pnlRatio: (Number(totalProfits) + Number(totalLoss)).toFixed(2),          
                mostProfit: mostProfit,
                mostLoss: mostLoss
            });
        } 

    }, [currencyDeals, state.enabledSimulator, state.analysisModel]);

    return (
        <>
            {isLoading && <AnalysisLoading current={0} total={0} spin={true} />}
            <div className='futures-detail container'>
                <Row gutter="16">
                    <Col span={8}>
                        <CurrencyInfo currency={currencyInfo} />
                    </Col>
                    <Col span={16}>
                        {/*<CurrencyNews symbolName={currencyInfo.name} />*/}
                        <div className="currency-graphic">
                            <div className='graphic-container'>
                                <TradingViewWidgetEmbed currency={currencyInfo} />
                            </div>
                        </div>
                    </Col>
                    <Col span={24} style={{marginBottom: 20}}>
                        <Row gutter={16}>
                            <Col span={8}>
                                <Card title="Total Position">
                                    <Statistic 
                                        title="Profit"
                                        value={totalValues.totalPosProcess}
                                        precision={0}
                                        valueStyle={{ color: '#3f8600' }}
                                        prefix={''}
                                        suffix="Process"
                                        />
                                    <Statistic
                                        title="Loss"
                                        value={totalValues.totalNegProcess}
                                        precision={0}
                                        valueStyle={{ color: '#cf1322' }}
                                        prefix={''}
                                        suffix="Process"
                                        />
                                    <Statistic
                                        title="Notr"
                                        value={totalValues.totalNotrProcess}
                                        precision={0}
                                        valueStyle={{ color: '#000' }}
                                        prefix={''}
                                        suffix="Process"
                                        />
                                </Card>
                            </Col>
                            <Col span={8}>
                                <Card title="Total Profit / Loss">
                                    <span className='pnl-ratio'>
                                        {totalValues.pnlRatio} %
                                    </span>
                                    <Statistic 
                                        title="Profit"
                                        value={totalValues.totalProfits}
                                        precision={2}
                                        valueStyle={{ color: '#3f8600' }}
                                        prefix={''}
                                        suffix="%"
                                        />
                                    <Statistic
                                        title="Loss"
                                        value={totalValues.totalLoss}
                                        precision={2}
                                        valueStyle={{ color: '#cf1322' }}
                                        prefix={''} 
                                        suffix="%"
                                        />
                                </Card>
                            </Col>
                            <Col span={8}>
                                <Card title="Most Profit / Loss">
                                    <Statistic 
                                        title="Profit"
                                        value={totalValues.mostProfit}
                                        precision={2}
                                        valueStyle={{ color: '#3f8600' }}
                                        prefix={''}
                                        suffix="%"
                                        />
                                    <Statistic
                                        title="Loss"
                                        value={totalValues.mostLoss}
                                        precision={2}
                                        valueStyle={{ color: '#cf1322' }}
                                        prefix={''}
                                        suffix="%"
                                        />
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={24}>
                        <div className="deals">
                            <Tabs defaultActiveKey="1" type="card" size={"large"}>
                                <TabPane tab={`Current Deals ( ${currencyDeals.length} )`} key="1">
                                    {currencyDeals && <CurrencyHistoricalTable data={currencyDeals} />}
                                </TabPane>
                            </Tabs>
                        </div>                     
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default Details;