import React, { useContext } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import './style.scss';
import { Avatar, Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

const UserInfo = () => {
    const { user, googleLogout } = useContext(AuthContext);

    return (
        <div className='user-info'>
            <Avatar src={user.photoURL} size={36} />
            <div className='user-name'>
                <p>Welcome</p>
                <h4>{user.displayName}</h4>
            </div>
            <Button type="primary" shape="circle" icon={<CloseOutlined />} size={"small"} onClick={googleLogout} />
        </div>
    );
};

export default UserInfo;