import React, {useState} from 'react';
import TechnicalAnalysis, { INTERVALS } from 'react-tradingview-technical-analysis';
import './style.scss';

const TechnicalAnalysisWidget = ({currency}) => {

    return (<>
      {currency?.symbol ? <TechnicalAnalysis symbol={currency.type === "FUTURES" ? `${currency.symbol}PERP` : `${currency.symbol}`} interval={INTERVALS.FIFTEEN_MINUTES} /> : ""}
    </>
    );
};

export default TechnicalAnalysisWidget;

/*
<!-- TradingView Widget BEGIN -->
<div class="tradingview-widget-container">
  <div class="tradingview-widget-container__widget"></div>
  <div class="tradingview-widget-copyright">TradingView'den <a href="https://tr.tradingview.com/symbols/NASDAQ-AAPL/technicals/" rel="noopener" target="_blank"><span class="blue-text">AAPL Teknik Analizi</span></a></div>
  <script type="text/javascript" src="https://s3.tradingview.com/external-embedding/embed-widget-technical-analysis.js" async>
  {
  "interval": "15m",
  "width": "100%",
  "isTransparent": false,
  "height": "100%",
  "symbol": "NASDAQ:AAPL",
  "showIntervalTabs": true,
  "locale": "tr",
  "colorTheme": "light"
}
  </script>
</div>
<!-- TradingView Widget END -->
*/
