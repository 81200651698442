import React, { useState, useEffect } from 'react';
import './style.scss';
import { Progress, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const antIcon = <LoadingOutlined style={{ fontSize: 40, color:'#E5AC17' }} spin />;

const AnalysisLoading = ({current, total, spin}) => {
    const [percent, setPercent] = useState(0);

    useEffect(() => {
        setPercent(current * (100 / total));
    }, [current, total]);

    return (
        <div className='analysis-loading'>
            {percent !== 0 && !spin && 
                <div className={`progress-bar ${percent === 100 ? "completed" : ""}`}>
                    <Progress percent={percent.toFixed(2)} />
                    <div className='progress-info'>
                        <span>{percent === 100 ? "Data was loaded." : "Data is Loading..."} {current} / {total}</span>
                    </div>
                </div>      
            }
            {(percent === 100 || spin) &&
                <div className="spinning">
                    <Spin indicator={antIcon} />
                    <div className='spin-info'>
                        <span>Data is processing...</span>
                    </div>
                </div>
            }
        </div>
    );
};

export default AnalysisLoading;