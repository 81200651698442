import React from 'react';
import './style.scss';
import { Card, Button } from 'antd';
import {Link} from 'react-router-dom';
const { Meta } = Card;

const Home = () => {
    return (
        <div className='home container-fluid'>
            <div className='analysis-header'>
                <Card
                    hoverable
                    style={{
                    width: '100%',
                    }}
                    cover={<img alt="example" src="https://www.forex4you.com/en/wp-content/uploads/sites/2/2020/02/5-Basics-of-Cryptocurrency-Technical-Analysis-new.png" />}
                >
                    <Meta title="Cryptoloque Analysis Bot & Wallet Tracking Tool" description="Get started now to capture accurate crypto transactions and manage crypto wallets from a single point." />
                    <Link to={`/analysis`}>
                        <Button type="primary" size='large' className='btn-analysis'>GET STARTED NOW</Button>
                    </Link>
                </Card>
            </div>
        </div>
    );
};

export default React.memo(Home);