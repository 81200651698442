import { Table } from 'antd';
import React, {useContext, useEffect} from 'react';
import {Context} from '../../store';
import {Link} from 'react-router-dom';
import './style.scss';
import CurrencyHistoricalTable from '../CurrencyHistoricalTable';
import {getUSDollar} from '../../libs/utility';

const AllProfitLossTable = ({data}) => {
    const {state} = useContext(Context);

    const linkPrefix = state.marketType === "FUTURES" ? "futures" : "spot";

    const columns = [
        {
          title: 'Symbol',
          dataIndex: 'symbol',
          key: 'symbol',
          render:  (symbol, record) => symbolLink(symbol, record),
          sorter: (a, b) => ('' + a.symbol).localeCompare(b.symbol)
        },
        {
          title: 'Profit Position',
          dataIndex: 'totalPosProcess',
          key: 'totalPosProcess',
          render: posPosition => <span style={{color: '#3f8600'}}>{posPosition} Process</span>,
          sorter: (a, b) => a.totalPosProcess - b.totalPosProcess
        },
        {
            title: 'Loss Position',
            dataIndex: 'totalNegProcess',
            key: 'totalNegProcess',
            render: negPosition => <span style={{color: '#cf1322'}}>{negPosition} Process</span>,
            sorter: (a, b) => a.totalNegProcess - b.totalNegProcess,
        },
        {
            title: 'Notr Position',
            dataIndex: 'totalNotrProcess',
            key: 'totalNotrProcess',
            render: notrPosition => <span style={{color: '#000'}}>{notrPosition} Process</span>,
            sorter: (a, b) => a.totalNotrProcess - b.totalNotrProcess,
        },
        {
            title: 'Total Profits',
            dataIndex: 'totalProfits',
            key: 'totalProfits',
            render: (totalProfits, record) => <span style={{color: '#3f8600'}}>{totalProfits} % ({getUSDollar(record.totalRealProfits)})</span>,
            sorter: (a, b) => a.totalProfits - b.totalProfits,
        },
        {
            title: 'Total Loss',
            dataIndex: 'totalLoss',
            key: 'totalLoss',
            render: (totalLoss, record) => <span style={{color: '#cf1322'}}>{totalLoss} % ({getUSDollar(record.totalRealLoss)})</span>,
            sorter: (a, b) => a.totalLoss - b.totalLoss,
        },
        {
            title: 'Total PNL Ratio',
            dataIndex: 'pnlRatio',
            key: 'pnlRatio',
            render: (pnlRatio, record) => <span>{pnlRatio} % ({getUSDollar(Number(record.realPnlRatio))})</span>,
            sorter: (a, b) => a.pnlRatio - b.pnlRatio,
        },
        {
            title: 'Most Profit',
            dataIndex: 'mostProfit',
            key: 'mostProfit',
            render: mostProfit => <span style={{color: '#3f8600'}}>{mostProfit} %</span>,
            sorter: (a, b) => a.mostProfit - b.mostProfit,
        },
        {
            title: 'Most Loss',
            dataIndex: 'mostLoss',
            key: 'mostLoss',
            render: mostLoss => <span style={{color: '#cf1322'}}>{mostLoss} %</span>,
            sorter: (a, b) => a.mostLoss - b.mostLoss,
        }
      ];

      const symbolLink = (symbol, record) => {
        const sym = state.symbols.find(x => x.symbol === symbol);
        const imageName = sym.iconUrl; // state.marketType === "FUTURES" ? sym?.baseAsset?.toLowerCase() + '.svg' : sym.icon;
        return <div className="symbol">
            <Link to={`/${linkPrefix}-currencies/${symbol.replace('USDT', '').toLowerCase()}`}>
                <img src={`${process.env.PUBLIC_URL}/images/crypto-logo/${imageName}`} alt="" />
                <span><strong>{symbol}</strong></span>
            </Link>
        </div>
    }

    const expandedRowRender = (group) => {
        return <CurrencyHistoricalTable data={[...state.oldPositions, ...state.openPositions].filter(x => x.symbol === group.symbol).sort((a, b) => b.openDate - a.openDate)} />;
      };

    return (
        <div className='all-profit-loss-table'>
            <Table columns={columns} dataSource={data} bordered pagination={false} expandedRowRender={expandedRowRender} />
        </div>
    );
};

//<CurrencyHistoricalTable data={state.allPositions} />

export default AllProfitLossTable;