import React, { useEffect, useContext, useState, useCallback } from 'react';
import { binanceService } from '../../services';
import { Context } from '../../store';
import { AuthContext } from '../../contexts/AuthContext';
import {Row, Col, Tabs, Radio, Button, DatePicker, Checkbox, Alert, InputNumber, Select, Collapse, message} from 'antd';
import AnalysisLoading from '../../components/AnalysisLoading';
import CurrentDealsTable from '../../components/CurrentDealsTable';
import CurrentAIDealsTable from '../../components/CurrentAIDealsTable';
import Last24HoursDealsTable from '../../components/Last24HoursDealsTable';
import AllDailyProfitLossTable from '../../components/AllDailyProfitLossTable';
import CoinCurrentStatus from '../../components/CoinCurrentStatus';
import {Link} from 'react-router-dom';
import { timePeriods, marketType, analysisTypes, processDirection } from '../../libs/enums';
import { convertCandlestickData } from '../../libs/utility';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import SupportResistanceBox from '../../components/SupportResistanceBox';
import { analysisModel } from '../../libs/enums';
import {
    allCurrencyCandlestickAnalysis, 
    allCurrencyCandlestickFilter, 
    allCurrencyLast24CandlestickFilter,
    allCurrencyDailyProfitLossFilter,
    allCurrencySupDemAnalysis
} from '../../libs/analysis-supdem';
import './style.scss';
import { storage } from "../../firebase-config";
import { ref, getDownloadURL, uploadBytes } from "firebase/storage";
//import localData  from '../../data/local-candlesticks1d.json';

const {TabPane} = Tabs;
const { Panel } = Collapse;


let allSymbolCandleStickList = {};

const Analysis = props => {
    const {state, dispatch} = useContext(Context);
    const { user } = useContext(AuthContext);
    const [isButtonVisible, setIsButtonVisible] = useState(true);
    const [symbolIndex, setSymbolIndex] = useState(0);
    const [updateSymbolIndex, setUpdateSymbolIndex] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    //const [currentDeals, setCurrentDeals] = useState([]);
    const [last24HoursDeals, setLast24HoursDeals] = useState([]);
    const [todayAllDeals, setTodayAllDeals] = useState([]);
    const [yesterdayAllDeals, setYesterdayAllDeals] = useState([]);
    const [tdbYesterdayAllDeals, setTdbYesterdayAllDeals] = useState([]);
    const [customDayAllDeals, setCustomDayAllDeals] = useState([]);

    const [analysisOptions, setAnalysisOptions] = useState(state.analysisOptions);

    const [getThemAllSupDem, setGetThemAllSupDem] = useState(false);
    const [supdemUpdateDisabled, setSupdemUpdateDisabled] = useState(false);

    const changeMarketType = (type) => {
        dispatch({type: "MARKET_TYPE", payload: type});
    }

    useEffect(() => {
        console.log(analysisOptions);
        dispatch({type: "SET_ANALYSIS_OPTIONS", payload: analysisOptions});
    }, [analysisOptions]);

    const filterAllPositionWithDate = useCallback((date) => {
        return [...state.oldPositions, ...state.openPositions].filter(p => new Date(p.openDate).toDateString() === date.toDateString()).sort((a, b) => b.openDate - a.openDate);
    }, [state.oldPositions, state.openPositions]);

    useEffect(() => {
        const activePositions = state.openPositions.sort((a, b) => b.openDate - a.openDate);
        //console.log('Active Positions', activePositions);
        setLast24HoursDeals(activePositions);

        const currentDate = new Date();
        const todayStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());

        setTodayAllDeals(filterAllPositionWithDate(todayStartDate));
        setIsLoading(false);

    }, [state.kLinesList, state.openPositions, filterAllPositionWithDate]);

    useEffect(() => {
        const currentDate = new Date();
        const todayStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
        const yesterdayStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 1);
        const tdbYesterdayStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 2);

        setYesterdayAllDeals(filterAllPositionWithDate(yesterdayStartDate));
        setTdbYesterdayAllDeals(filterAllPositionWithDate(tdbYesterdayStartDate));
        setCustomDayAllDeals([]);
        setIsLoading(false);

    }, [filterAllPositionWithDate]);

    const runAnalysisAndSaveData = useCallback(() => {
        console.log('state analysis options:', state.analysisOptions);

        /*
        if(!state.analysisOptions.localData) {
            const element = document.createElement("a");
            const textFile = new Blob([JSON.stringify(allSymbolCandleStickList)], {type: 'text/plain'}); //pass data from localStorage API to blob
            element.href = URL.createObjectURL(textFile);
            element.download = "localData.txt";
            document.body.appendChild(element); 
            element.click();
        } 
        */
        //console.log('state.supdemData', JSON.parse(JSON.stringify(state.supdemData)));
        allCurrencyCandlestickAnalysis(allSymbolCandleStickList, state.symbols, state.analysisModel, state.enabledSimulator, state.analysisOptions, state.supdemData).then(data => {
            console.log('Then içinden gelen data', data);
            dispatch({type: "SAVE_KLINES_LIST", payload: data});
            allSymbolCandleStickList = {};
            setSymbolIndex(0);
        });
    }, [dispatch, state.symbols, state.analysisModel, state.enabledSimulator, state.analysisOptions, state.supdemData]);


    const startToFetchData = useCallback((index = 0) => {
        isButtonVisible && setIsButtonVisible(false);
        !isLoading && setIsLoading(true);

        let count = state.enabledSimulator ? '500' : '1500';

        if(analysisOptions.localData) {
            //allSymbolCandleStickList = localData;
            setSymbolIndex(state.symbols.length);

            setTimeout(() => {
                runAnalysisAndSaveData();
            }, 1000);
        } else {
            if(state.marketType === 'FUTURES') {
                binanceService.getFuturesKlines(state.symbols[index].symbol, analysisOptions.timePeriod, count)
                    .then(res => {
                        res.splice(res.length - 1, 1);
                        allSymbolCandleStickList[state.symbols[index].symbol] = {candles: convertCandlestickData(res)}; 

                        if(symbolIndex === state.symbols.length - 1) {
                            setTimeout(() => {
                                runAnalysisAndSaveData();
                            }, 1000);
                        }
                        
                        setSymbolIndex(symbolIndex + 1);
                    });                
            }
            else {
                //console.log('startToFetchData state.supdemData', JSON.parse(JSON.stringify(state.supdemData)));
                binanceService.getSpotKlines(state.symbols[index].symbol, analysisOptions.timePeriod, count)
                //binanceService.getSpotKlinesWithDate(state.symbols[index].symbol, analysisOptions.timePeriod, count, 1724360400000)
                    .then(res => {
                        res.splice(res.length - 1, 1);
                        allSymbolCandleStickList[state.symbols[index].symbol] = {candles: convertCandlestickData(res)}; 
                        if(symbolIndex === state.symbols.length - 1) {
                            //console.log('state.supdemData', JSON.parse(JSON.stringify(state.supdemData)));
                            setTimeout(() => {
                                runAnalysisAndSaveData();
                            }, 1000);
                        }
                        
                        setSymbolIndex(symbolIndex + 1);
                    });
            }
        }
    }, [dispatch, isButtonVisible, isLoading, symbolIndex, state.symbols, runAnalysisAndSaveData, state.enabledSimulator, analysisOptions]);

    useEffect(() => {
        //console.log('Symbol Index', symbolIndex);
        if(symbolIndex > 0 && symbolIndex !== state.symbols.length) {
            startToFetchData(symbolIndex);
        }
        //if(symbolIndex === state.symbols.length) {
        //    runAnalysisAndSaveData();
        //}
    }, [symbolIndex, startToFetchData, state.symbols.length]);

    const tabOnChange = (activeKey) => {
        //console.log(activeKey);
    }

    const ema200UpDownRender = (dealType) => {
        let iconRender = "";
        switch(dealType) {
            case "DownUp":
                iconRender = <div>
                    <CaretDownOutlined style={{ fontSize: '22px', color: '#f6465d' }} />
                    <CaretUpOutlined style={{ fontSize: '22px', color: '#0ecb81' }} />
                </div>;
                break;
            case "Down":
                iconRender = <div>
                    <CaretDownOutlined style={{ fontSize: '22px', color: '#f6465d' }} />
                </div>;
                break;
            case "UpDown":
                iconRender = <div>
                    <CaretUpOutlined style={{ fontSize: '22px', color: '#0ecb81' }} />
                    <CaretDownOutlined style={{ fontSize: '22px', color: '#f6465d' }} />
                </div>;
                break;
            case "Up":
                iconRender = <div>
                    <CaretUpOutlined style={{ fontSize: '22px', color: '#0ecb81' }} />
                </div>;
                break;
            default:
                iconRender = "";
        }

        return iconRender;
    }

    const renderOtherDeals = (dealsArray, col) => {
        const linkPrefix = state.marketType === "FUTURES" ? "futures" : "spot";

        let deals = [];
        if(dealsArray.length > 0) {
            deals = dealsArray.map(deal => {
                const sym = state.symbols.find(x => x.symbol === deal.symbol);
                const imageName = sym.iconUrl; // state.marketType === "FUTURES" ? sym?.baseAsset?.toLowerCase() + '.svg' : sym.icon;     
                return (
                    <Col xs={12} sm={8} md={6} lg={6} xl={col} key={deal.key}>
                        <div className="other-deal-symbol">
                            <Link to={`/${linkPrefix}-currencies/${deal.symbol.replace('USDT', '').toLowerCase()}`}>
                                {<img src={`${process.env.PUBLIC_URL}/images/crypto-logo/${imageName}`} alt="" />}
                                <span><strong>{deal.symbol}</strong></span>
                                {deal.rsi && <span className="rsi">[ {deal.rsi} ]</span>}
                                {deal.type && <div className="ema200-up-down">{ema200UpDownRender(deal.type)}</div>}
                            </Link>
                        </div>
                    </Col>                
                );    
            });
        }
        return deals;
    }

    const renderSrDeals = (dealsArray) => {
        let deals = [];
        if(dealsArray.length > 0) {
            deals = dealsArray.map(deal => {
                return (
                    <SupportResistanceBox sr={deal} key={deal.symbol} />
                );    
            })
        }
        return deals;
    }

    const getCustomDayDeals = (date, dateString) => {
        //console.log(date, dateString);
        let startDate = new Date(date._d.getFullYear(), date._d.getMonth(), date._d.getDate());
        //let endDate = new Date(date._d.getFullYear(), date._d.getMonth(), date._d.getDate() + 1);
        //console.log(startDate, endDate);

        setCustomDayAllDeals(filterAllPositionWithDate(startDate));
        setIsLoading(false);
    }

    const onChangeAnalysisTypes = (checkedValues) => {
        let tempAnalysisTypes = {...state.analysisOptions.analysisTypes};

        Object.keys(tempAnalysisTypes).map(o => {
            tempAnalysisTypes[o] = false;
        })

        checkedValues.map(c => {
            tempAnalysisTypes[c] = true;
        });

        setAnalysisOptions({...analysisOptions, analysisTypes: tempAnalysisTypes});
    }
    
    const onChangeProcessDirection = (checkedValues) => {
        let tempProcessDirection = {...state.analysisOptions.processDirection};

        Object.keys(tempProcessDirection).map(o => {
            tempProcessDirection[o] = false;
        })

        checkedValues.map(c => {
            tempProcessDirection[c] = true;
        });

        setAnalysisOptions({...analysisOptions, processDirection: tempProcessDirection});
    }

    const analysisSupDemData = useCallback((getThemAll) => {
        //console.log('analysisSupDemData', allSymbolCandleStickList);

        // get them all true durumunda önceki sup dem datayı almadan sıfırdan bir başlangıç gerekiyor.
        allCurrencySupDemAnalysis(state.supdemData.symbols, allSymbolCandleStickList, getThemAll).then(data => {
            console.log('allCurrencySupDemAnalysis THEN Success', data);
            setUpdateSymbolIndex(0);
            allSymbolCandleStickList = {};
            let jsonRef = null;
            if (state.marketType === "FUTURES") {
                jsonRef = ref(storage, 'json/supdem-futures.json');
            } else {
                jsonRef = ref(storage, 'json/supdem-spot.json');
            }

            const newSupDemData = new Blob([JSON.stringify(data)], { type: "application/json" });
            
            uploadBytes(jsonRef, newSupDemData)
                .then((snapshot) => {
                    console.log('uploadBytes', snapshot);
                    dispatch({type: "SAVE_SUPDEM_DATA", payload: data});
                    message.success('Veri güncellemesi tamamlandı.');
                })
                .catch((error) => {
                console.log(error.message);
                })
                .finally(() => {
                    setSupdemUpdateDisabled(false);
                });
                
                //dispatch({type: "SAVE_SUPDEM_DATA", payload: data});
                //setSupdemUpdateDisabled(false);
            // buradaki datayı alıp state içindeki supdemi güncellicez. ve gidip firebasedeki dosyanın üzerine yazıcaz.
        });
    }, [state.supdemData.symbols]);


    const updateSupDemData = (index = 0, getThemAll) => {

        if(getThemAllSupDem !== getThemAll) {
            setGetThemAllSupDem(getThemAll);
        }

        if (index === 0) {
            setSupdemUpdateDisabled(true);
        }

          var diffHours = Math.abs(Date.now() - state.supdemData.lastUpdate) / 36e5;
          if(getThemAll || diffHours > 8) {
            // bir sonraki 4 saatlik mumda kapanmıştır. yeni verileri getirmek gerekir.
            //message.success('Güncelleme gerekiyor!');
            // BURADA LOADING EKLENEBİLİR.
            
            let count = state.marketType === 'FUTURES' ? 1500 : 1000;

            if(state.marketType === 'FUTURES') {
                let getFuturesSupDemData = getThemAll ? binanceService.getFuturesKlines(state.symbols[index].symbol, '4h', count) : binanceService.getFuturesKlinesWithDate(state.symbols[index].symbol, '4h', count, state.supdemData.lastUpdate);
                getFuturesSupDemData
                    .then(res => {
                        res.shift();
                        res.pop();
                        allSymbolCandleStickList[state.symbols[index].symbol] = {candles: convertCandlestickData(res)}; 

                        if(updateSymbolIndex === state.symbols.length - 1) {
                            setTimeout(() => {
                                analysisSupDemData(getThemAll);
                            }, 1000);
                        }
                        
                        setUpdateSymbolIndex(updateSymbolIndex + 1);
                    });                
            }
            else {
                let getSpotSupDemData = getThemAll ? binanceService.getSpotKlines(state.symbols[index].symbol, '4h', count) : binanceService.getSpotKlinesWithDate(state.symbols[index].symbol, '4h', count, state.supdemData.lastUpdate);
                //console.log('getSpotSupDemData', getThemAll, getSpotSupDemData);
                getSpotSupDemData
                    .then(res => {
                        res.shift();
                        res.pop();
                        allSymbolCandleStickList[state.symbols[index].symbol] = {candles: convertCandlestickData(res)}; 

                        if(updateSymbolIndex === state.symbols.length - 1) {
                            setTimeout(() => {
                                analysisSupDemData(getThemAll);
                            }, 1000);
                        }
                        
                        setUpdateSymbolIndex(updateSymbolIndex + 1);
                    });
            }

          } else {
            message.info('Yeni veri bulunamadı.');
          }
    }

    useEffect(() => {
        //console.log('Symbol Index', symbolIndex);
        if(updateSymbolIndex > 0 && updateSymbolIndex !== state.symbols.length) {
            updateSupDemData(updateSymbolIndex, getThemAllSupDem);
        }
    }, [updateSymbolIndex, updateSupDemData, state.symbols.length]);

    return (
        <>
            {isLoading && <AnalysisLoading current={symbolIndex} total={state.symbols.length} spin={state.currentDeals.length > 0 ? true : false} />}
            <div className='analysis container'>
                <Row gutter="16">
                    {Object.keys(state.kLinesList).length > 0 && 
                    <>
                        <Col span={12}>
                            <CoinCurrentStatus marketType={"FUTURES"} symbol={"BTCUSDT"} />
                        </Col>
                        <Col span={12}>
                            <CoinCurrentStatus marketType={"FUTURES"} symbol={"ETHUSDT"} />
                        </Col>
                    </>
                    }
                    <Col span={24}>
                        {(isButtonVisible && Object.keys(state.kLinesList).length === 0) && 
                            <div className="ai-options">
                                <Row justify="center">
                                    {/*
                                    <Col span={24} className="select-model">
                                        <p>Please Select Analysis Model:</p>
                                        <Radio.Group options={analysisModel} onChange={({target: {value}}) => {setAnalysisOptions({...analysisOptions, model: value})}} value={analysisOptions.model} optionType="button" buttonStyle="solid" />
                                    </Col>                                    
                                    */}

                                    <Col span={24} className="select-mt">
                                        <p>Select Market Type:</p>
                                        <Radio.Group options={marketType} onChange={({target: {value}}) => changeMarketType(value)} value={state.marketType} optionType="button" buttonStyle="solid" />
                                    </Col>

                                    <Col span={24} className="select-tf">
                                        <p>Select Time Period:</p>
                                        <Radio.Group options={timePeriods} onChange={({target: {value}}) => {setAnalysisOptions({...analysisOptions, timePeriod: value})}} value={analysisOptions.timePeriod} optionType="button" buttonStyle="solid" />
                                    </Col>

                                    <Col span={24} style={{marginBottom: 15, marginTop: 10}}>
                                        <Row>
                                            <Col span={24} className={'process-direction'}>
                                                <Checkbox.Group options={processDirection} defaultValue={['long']} onChange={onChangeProcessDirection} />
                                            </Col>
                                        </Row>
                                    </Col>

                                    {analysisOptions.model !== "SD" &&
                                    <>
                                        <Col>
                                            <Row gutter={16}>
                                                <Col span={12}>
                                                    <span className='form-label'>Pivot Period:</span>
                                                    <InputNumber placeholder='Enter pivot period.' name="pivotPeriod" value={analysisOptions.pivotPeriod} onChange={value => setAnalysisOptions({...analysisOptions, pivotPeriod: value})} />
                                                    {/*<Checkbox onChange={(e) => {setAnalysisOptions({...analysisOptions, noClosing: e.target.checked})}} checked={analysisOptions.noClosing}>No Closing Positions</Checkbox>*/}
                                                </Col>
                                                <Col span={12}>
                                                    <span className='form-label'>Pivot Source:</span>
                                                    <Select 
                                                        value={analysisOptions.pivotSource}
                                                        placeholder="Select source" 
                                                        optionFilterProp="children" 
                                                        style={{ width: '100%'}} 
                                                        onChange={(val) => {setAnalysisOptions({...analysisOptions, pivotSource: val})}}
                                                        options={
                                                            [{value: 'close', label: 'Close'}, {value: 'high-low', label: 'High-Low'}]
                                                        }></Select>
                                                </Col>
                                            </Row>
                                        </Col>

                                        <Col span={24} style={{marginBottom: 15, marginTop: 10}}>
                                            <Row>
                                                <Col span={24} className={'check-types'}>
                                                    <Collapse>
                                                        <Panel key="1" header='Customize Analysis Types'>
                                                            <Checkbox.Group options={analysisTypes} defaultValue={analysisTypes.map(a => a.value)} onChange={onChangeAnalysisTypes} />
                                                        </Panel>
                                                    </Collapse>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </>
                                    }

                                    {!analysisOptions.noClosing &&
                                        <Row gutter={16}>
                                            <Col span={12}>
                                                <span className='form-label'>Stop Loss (%):</span>
                                                <InputNumber placeholder='Enter stop loss percentage.' name="stopLoss" value={analysisOptions.stopLoss} onChange={value => setAnalysisOptions({...analysisOptions, stopLoss: value})} />
                                            </Col>
                                            <Col span={12}>
                                                <span className='form-label'>Take Profit (%):</span>
                                                <InputNumber placeholder='Enter take profit percentage.' name="takeProfit" value={analysisOptions.takeProfit} onChange={value => setAnalysisOptions({...analysisOptions, takeProfit: value})} />
                                            </Col>
                                            <Col span={24}>
                                                <span className='form-label'>Entry Stop Trigger (%):</span>
                                                <InputNumber placeholder='Enter entry stop price.' name="entryStop" value={analysisOptions.entryStop} onChange={value => setAnalysisOptions({...analysisOptions, entryStop: value})} />
                                            </Col>
                                            <Col span={24}>
                                                <span className='form-label'>Trailing Stop Activation Price (%):</span>
                                                <InputNumber placeholder='Enter activation price.' name="trailingActivation" value={analysisOptions.trailingActivation} onChange={value => setAnalysisOptions({...analysisOptions, trailingActivation: value})} />
                                            </Col>
                                            <Col span={24}>
                                                <span className='form-label'>Trailing Stop Callback Rate (%):</span>
                                                <InputNumber placeholder='Enter callback rate.' name="trailingCallback" min={0} max={10} value={analysisOptions.trailingCallback} onChange={value => setAnalysisOptions({...analysisOptions, trailingCallback: value})} />
                                            </Col>
                                            <Col span={24}>
                                                <span className='form-label'>Time Limit (Time Period Count):</span>
                                                <InputNumber placeholder='Enter time period count.' name="posTimeLimit" value={analysisOptions.posTimeLimit} onChange={value => setAnalysisOptions({...analysisOptions, posTimeLimit: value})} />
                                            </Col>
                                            <Col span={24}>
                                                <span className='form-label'>Entry Amount (Entry Cost Per Trade):</span>
                                                <InputNumber placeholder='Enter entry amount.' name="entryAmount" value={analysisOptions.entryAmount} onChange={value => setAnalysisOptions({...analysisOptions, entryAmount: value})} />
                                            </Col>
                                        </Row>
                                    }
                                    <Col span={24}>
                                        <Button type="primary" size='large' block className='btn-analysis' onClick={() => startToFetchData(0)}>Start to Analysis</Button>
                                    </Col>
                                </Row>  
                            </div>

                        }                

                        {Object.keys(state.kLinesList).length > 0 && 
                            <div className="deals">
                                <Tabs defaultActiveKey="1" type="card" size={"large"} onChange={tabOnChange}>
                                    <TabPane tab={`Current Deals ( ${state.currentDeals.length} )`} key="1">
                                        {state.currentDeals && <CurrentAIDealsTable data={state.currentDeals} />}
                                    </TabPane>
                                    <TabPane tab={`Active Deals ( ${last24HoursDeals.length} )`} key="2">
                                        {last24HoursDeals && <Last24HoursDealsTable data={last24HoursDeals} />}
                                    </TabPane>
                                    <TabPane tab={`Today All Deals ( ${todayAllDeals.length} )`} key="3">
                                        {todayAllDeals && <AllDailyProfitLossTable data={todayAllDeals} />}
                                    </TabPane>
                                    <TabPane tab={`Yesterday All Deals ( ${yesterdayAllDeals.length} )`} key="4">
                                        {yesterdayAllDeals && <AllDailyProfitLossTable data={yesterdayAllDeals} />}
                                    </TabPane>
                                    <TabPane tab={`TDB Yesterday All Deals ( ${tdbYesterdayAllDeals.length} )`} key="5">
                                        {tdbYesterdayAllDeals && <AllDailyProfitLossTable data={tdbYesterdayAllDeals} />}
                                    </TabPane>
                                    <TabPane tab={`Custom Day All Deals ( ${customDayAllDeals.length} )`} key="6">
                                        <DatePicker size="large" onChange={getCustomDayDeals} style={{ marginBottom: 15}} />
                                        {customDayAllDeals && <AllDailyProfitLossTable data={customDayAllDeals} />}
                                    </TabPane>
                                </Tabs>
                            </div> 
                        } 

                    </Col>

                    {/*Object.keys(state.kLinesList).length > 0 && 
                        <Col span={24}>
                            <div className="other-deals">
                                <Tabs defaultActiveKey="0" type="card" size={"large"}>
                                    <TabPane tab={`Trend Breaks Deals ( ${state.currentTrendBreaks.length} )`} key="0">
                                        <Row gutter="16">
                                            {state.currentTrendBreaks && renderOtherDeals(state.currentTrendBreaks, 4)}
                                        </Row>
                                    </TabPane>
                                    <TabPane tab={`Support/Resistance Deals ( ${state.currentSrDeals.length} )`} key="1">
                                        <Row gutter="16">
                                            <Col span={24}>
                                                <div style={{width: '50%', marginBottom: '10px'}}>
                                                    <Alert message={
                                                        <><strong>Trading View Indicator Settings:</strong> Custom Bar Back: <strong>500</strong> | Pivot Period: <strong>{analysisOptions.pivotPeriod}</strong> | Auto Simplification: <strong>true</strong> | Simplification Steps: <strong>14</strong> (default)</>
                                                    } type="warning" showIcon closable style={{textAlign: 'left'}} />                                               
                                                </div> 
                                            </Col>

                                            {state.currentSrDeals && renderSrDeals(state.currentSrDeals)}
                                        </Row>
                                    </TabPane>
                                    <TabPane tab={`Ema 200 Deals ( ${state.currentEma200Deals.length} )`} key="2">
                                        <Row gutter="16">
                                            {state.currentEma200Deals && renderOtherDeals(state.currentEma200Deals, 4)}
                                        </Row>
                                    </TabPane>
                                    <TabPane tab={`Ema 100 Deals ( ${state.currentEma100Deals.length} )`} key="3">
                                        <Row gutter="16">
                                            {state.currentEma100Deals && renderOtherDeals(state.currentEma100Deals, 4)}
                                        </Row>
                                    </TabPane>
                                    <TabPane tab={`Above EMA 20 Deals ( ${state.currentEma20Deals.length} )`} key="4">
                                        <Row gutter="16">
                                            {state.currentEma20Deals && renderOtherDeals(state.currentEma20Deals, 3)}
                                        </Row>
                                    </TabPane>
                                    <TabPane tab={`Above RSI 30 Deals ( ${state.currentRsiUp30Deals.length} )`} key="5">
                                        <Row gutter="16">
                                            {state.currentRsiUp30Deals && renderOtherDeals(state.currentRsiUp30Deals, 4)}
                                        </Row>
                                    </TabPane>
                                    <TabPane tab={`Below RSI 30 Deals ( ${state.currentRsiDown30Deals.length} )`} key="6">
                                        <Row gutter="16">
                                            {state.currentRsiDown30Deals && renderOtherDeals(state.currentRsiDown30Deals, 4)}
                                        </Row>
                                    </TabPane>
                                    <TabPane tab={`Upward Expansions ( ${state.currentUpwardExpansions.length} )`} key="7">
                                        <Row gutter="16">
                                            {state.currentUpwardExpansions && renderOtherDeals(state.currentUpwardExpansions, 4)}
                                        </Row>
                                    </TabPane>
                                </Tabs>
                            </div>                     
                        </Col>                    
                    */}

                    {user.uid === "2N94LxkquqZWiRawQiJIYNaVhi32" &&
                        <Col span={24} style={{marginTop: 20}}>
                            <Button type={'primary'} className="btn-cryptoverse" onClick={() => updateSupDemData(0, true)} style={{marginRight: 15}} disabled={supdemUpdateDisabled}>Get All Supply Demand Data</Button>
                            <Button type={'primary'} className="btn-cryptoverse" onClick={() => updateSupDemData(0, false)} disabled={supdemUpdateDisabled}>Update Supply Demand Data</Button>
                        </Col>
                    }
                </Row>
            </div>
        </>
    );
};

export default React.memo(Analysis);
