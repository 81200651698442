import {useRef} from 'react';
import '../../App.scss';
import {Layout, Drawer} from 'antd';
import Router from '../../router';
import Header from '../../components/Layout/Header';
import Footer from '../../components/Layout/Footer';
import { Context } from '../../store';
import { useCallback, useContext, useEffect, useState } from 'react';
//import websocket from '../../libs/websocket';
import Websocket from '../../libs/websocket-new';
import {createCandleStickSocketUrl, convertSocketMessageToCandleStick, getPassingTime, allMarketMarkPriceStreamUrl} from '../../libs/utility';
import {allCurrencyCandlestickFilter, allCurrencyLastCandlestickAnalysis, allCurrencyUpdateNewModelAnalysis} from '../../libs/analysis-supdem';
import InfoModal from '../../components/InfoModal';
import CalculatorModal from '../../components/CalculatorModal';
import AnalysisModelModal from '../../components/AnalysisModelModal';
import AnalysisLoading from '../../components/AnalysisLoading';
import MobileDrawer from '../../components/MobileDrawer';
import { storage } from "../../firebase-config";
import { ref, getDownloadURL } from "firebase/storage";
import { e } from 'mathjs';

//let currentCandleSticks = {};

const alert = new Audio(process.env.PUBLIC_URL + '/sounds/linn.mp3');
//const vuvuzela = new Audio(process.env.PUBLIC_URL + '/sounds/vuvuzela.mp3');
const vikingHorn = new Audio(process.env.PUBLIC_URL + '/sounds/viking_horn.mp3');

const forceClosePosition = (openPosition, currentKlineValues, klines, type) => {
  console.log('Force Close', openPosition, currentKlineValues, type);
  let symbolKlinesList = [...klines[currentKlineValues.k.s]];
  //console.log('Symbol Kline List', symbolKlinesList);
  let symbolLastKline = symbolKlinesList[klines[currentKlineValues.k.s].length - 1];
  //console.log('Symbol Last Kline', symbolLastKline);
  let lastPosition = symbolLastKline.position;
  //console.log('Last Position', JSON.stringify(lastPosition));

  //console.log('Force Update Func Positions', positions.filter(p => p.closeType === 'NC').length);

  if(lastPosition.candleCount === 0) {
    lastPosition.candleCount = 1;
  }

  const closeMinusPerc = (Number(currentKlineValues.k.c) - lastPosition.openPrice) * 100 / lastPosition.openPrice;
  let tempCloseReverse = lastPosition.closeReverse;
  lastPosition.closeReverse = closeMinusPerc < tempCloseReverse ? Number(closeMinusPerc.toFixed(2)) : lastPosition.closeReverse;
  lastPosition.closeReverseIndex = closeMinusPerc < tempCloseReverse ? lastPosition.candleCount : lastPosition.closeReverseIndex;

  const maxMinusPerc = (Number(currentKlineValues.k.l) - lastPosition.openPrice) * 100 / lastPosition.openPrice;
  let tempMaxReverse = lastPosition.maxReverse;
  lastPosition.maxReverse = maxMinusPerc < tempMaxReverse ? Number(maxMinusPerc.toFixed(2)) : lastPosition.maxReverse;
  lastPosition.maxReverseIndex = maxMinusPerc < tempMaxReverse ? lastPosition.candleCount : lastPosition.maxReverseIndex;

  lastPosition.highPriceIndex = Number(currentKlineValues.k.c) > lastPosition.highPrice ? lastPosition.candleCount : lastPosition.highPriceIndex;
  lastPosition.highPrice = Number(currentKlineValues.k.c) > lastPosition.highPrice ? Number(currentKlineValues.k.c) : lastPosition.highPrice;
  lastPosition.topNeedleIndex = Number(currentKlineValues.k.h) > lastPosition.topNeedle ? lastPosition.candleCount : lastPosition.topNeedleIndex;
  lastPosition.topNeedle = Number(currentKlineValues.k.h) > lastPosition.topNeedle ? Number(currentKlineValues.k.h) : lastPosition.topNeedle;
  if(lastPosition.candleCount === 1) {
    lastPosition.firstReverse = maxMinusPerc < tempMaxReverse ? Number(maxMinusPerc.toFixed(2)) : lastPosition.maxReverse;
  } 

  lastPosition.closePrice = Number(currentKlineValues.k.c);
  lastPosition.closeDate = currentKlineValues.E;
  //lastPosition.time = (new Date(currentKlineValues.E).getTime() - new Date(lastPosition.openDate).getTime()) / (1000 * 60 * 60);
  lastPosition.time = getPassingTime(new Date(currentKlineValues.E), new Date(lastPosition.openDate));
  let pnl = Number(((currentKlineValues.k.c - lastPosition.openPrice) * 100 / lastPosition.openPrice).toFixed(2));
  pnl = pnl < -5 ? -5 : pnl;

  lastPosition.pnl = lastPosition.status === "SHORT" ? -1 * pnl : pnl; 
  lastPosition.closeType = `B (${type})`;
  lastPosition.highProfit = Number(((lastPosition.highPrice - lastPosition.openPrice) * 100 / lastPosition.openPrice).toFixed(2));
  lastPosition.mostProfit = Number(((lastPosition.topNeedle - lastPosition.openPrice) * 100 / lastPosition.openPrice).toFixed(2));

  //console.log(`close date: ${lastPosition.closeDate}, open date: ${lastPosition.openDate}, open price: ${lastPosition.openPrice}, last position: ${lastPosition}`);

  // all position object push
  //allPastPositionsList.push({...currentDeal});
  // BURADA allPositionsList içerisinde mevcut pozisyon update edilecek.
  openPosition = {...openPosition, ...lastPosition};

  lastPosition.status = lastPosition.candleCount === 1 ? lastPosition.status + "CLOSE" : "CLOSE"; // Eğer pozisyon ilk mumda hemen kapanıyorsa 'LONGCLOSE' olarak setlenecek.
  // BURADA mevcut symbolun son mumunun değerleri update edilecek.
  symbolLastKline.position = lastPosition;
  symbolKlinesList.pop();
  symbolKlinesList.push(symbolLastKline);

return {symbol: currentKlineValues.s, candlesticks: symbolKlinesList, position: openPosition}
};

const forceClosePositions = (closePositions) => {
  console.log('kapanacak pozisyonlar', closePositions);
}

function AuthApp() {
  const {state, dispatch} = useContext(Context);
  const [currentCandleSticks, setCurrentCandleSticks] = useState({});
  const [currentPriceTrackingCandlesticks, setCurrentPriceTrackingCandlesticks] = useState({});
  const [open, setOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [markPriceSocketMessage, setMarkPriceSocketMessage] = useState(null);

  const analysisModelRef = useRef();
  //console.log('Auth App Render', state.kLinesList);

  const showDrawer = () => {
    console.log('show drawer');
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    let jsonRef = null;

    if(state.marketType === "FUTURES") {
      jsonRef = ref(storage, 'json/supdem-futures.json');
    } else {
      jsonRef = ref(storage, 'json/supdem-spot.json');
    }
    getDownloadURL(jsonRef)
      .then((url) => fetch(url))
      .then(response => response.json())
      .then((data) => {
        console.log('get json file', jsonRef, data);
        if(data) {
          dispatch({type: "SAVE_SUPDEM_DATA", payload: data});
          
          /*
          var diffHours = Math.abs(Date.now() - data.lastUpdate) / 36e5;
          if(diffHours > 8) {
            // bir sonraki 4 saatlik mumda kapanmıştır. yeni verileri getirmek gerekir.
          }
          */
        }
      })
      .catch((error) => {
        // Handle any errors
        console.log('get file error', error);
      });
  }, [state.marketType]);

  useEffect(() => {
    console.log('All State', state);
  }, [state]);


  useEffect(() => {console.log('open', open);}, [open]);

  useEffect(() => {
    console.log('old positions use effect', state.oldPositions);
    console.log('open positions use effect', state.openPositions);
      console.log('klines list use effect', state.kLinesList);
    console.log('SUPPORT RESISTANCE', state.supports);

    // burada aslında klinesList'e gerek yok ama allPositions state güncellemesi ilk anda tetiklenmiyor. Çok ilginç??
  }, [state.oldPositions, state.openPositions, state.kLinesList, state.supports]);


  const listenMessage = useCallback((message) => {   
    //console.log('Socket Message', message.data);
    if(message.data.k.x) {
      //console.log('Socket Message 15m Updates', message); 
      //console.log('listenMessage', message.data.s, message.data);  
      setCurrentCandleSticks(prevState => ({
        ...prevState, 
        [message.data.s]: {candles: convertSocketMessageToCandleStick(message.data)} 
      }));
    }
  }, []);

  const listenPriceTrackingMessage = useCallback((message) => {
    if(message.data.k.x) {
      //console.log('Socket Message 1m Updates', message);   
      setCurrentPriceTrackingCandlesticks(prevState => ({
        ...prevState, 
        [message.data.s]: convertSocketMessageToCandleStick(message.data)
      }));
    }
  }, []);

  useEffect(() => {
    //console.log('Current Deals State', state.currentDeals);
    if(!!state.currentDeals?.find(c => c.status === 'OPEN LONG')) {
      vikingHorn.play(); 
    }
  }, [state.currentDeals]);

  useEffect(() => {
    //console.log('Current EMA 200 Deals State', state.currentEma200Deals);
    if(state.currentEma200Deals.length > 0) {
      alert.play(); 
    }
  }, [state.currentEma200Deals]);

  useEffect(() => {
    if(Object.keys(state.kLinesList).length !== 0) {
      allCurrencyCandlestickFilter(state.kLinesList, state.supports).then(data => {
        //console.log('CURRENT DEALS', data);
        dispatch({type: "UPDATE_CURRENT_DEALS", payload: data});
    });
    }
  }, [state.kLinesList, state.symbols, dispatch, state.supports]);

  useEffect(() => {
    // console.log('listen message total update', Object.keys(currentCandleSticks).length, state.symbols.length);
    // console.log('listen message lists', currentCandleSticks, state.symbols);
    // coinList.filter(c => !Object.keys(socketList).join(',').includes(c.symbol))
    if(Object.keys(currentCandleSticks).length === state.symbols.length) {
      allCurrencyLastCandlestickAnalysis(state.kLinesList, currentCandleSticks, state.symbols, state.analysisModel, state.openPositions, state.analysisOptions, state.supdemData).then(data => {
        //console.log('işlenmiş güncel data', data);
        setCurrentCandleSticks({});  
        dispatch({type: "UPDATE_KLINES_LIST", payload: data});
        //alert.play();
      });
    }
  }, [currentCandleSticks, state.symbols, state.marketType, dispatch, state.kLinesList, state.analysisModel, state.openPositions, state.analysisOptions, state.supdemData]);

  // YENİ YAPILAN SOKET TAKİP UEEFFECTI
  useEffect(() => {
    if(Object.keys(currentPriceTrackingCandlesticks).length === state.symbols.length) {

      
      const updatedHighPricePos = [];
      const updatedClosePos = [];

      if(state.openPositions.length > 0) {
        state.openPositions.map(pos => {
          const openPosCurrentValues = currentPriceTrackingCandlesticks[pos.symbol];

          let openPrice = pos.openPrice;
          let highPrice = pos.topNeedle > openPosCurrentValues[2] ? pos.topNeedle : openPosCurrentValues[2];
          let currentClosePrice = openPosCurrentValues[4];

          if(highPrice > pos.topNeedle) {
            pos.topNeedle = highPrice;
            updatedHighPricePos.push(pos);
          }

          if(true) {
            // güncel kapanış, açılış fiyatının %3 altına indiyse ya da eşitse %3 zararla kapat.
          }

          if(true) {
            // 
          }
        });
      }
      

      setCurrentPriceTrackingCandlesticks({}); 






/*

      if(Object.keys(window.openPositions).length > 0) {
        console.log(window.openPositions);
        const updatedHighPricePos = [];
        const updatedClosePos = [];
  
        Object.keys(window.openPositions).map(pos => {
          const openPos = window.openPositions[pos];
          const openPosCurrentValues = currentPriceTrackingCandlesticks[openPos.symbol];

          //const posCurrentPrice = markPriceSocketMessage.find(m => m.s === openPos.symbol);
          //console.log(markPriceSocketMessage, posCurrentPrice);
  
          let openPrice = openPos.openPrice;
          let closePrice = posCurrentPrice.p;
          let highPrice = openPos.topNeedle;
  
          if(openPos.topNeedle < posCurrentPrice.p) {
            // close price topNeedle'dan büyükse highPrice'ı setle, pozisyon içindeki topNeedle değerini güncelle
            // updatedHighPricePos arrayine güncellenen pozisyonu ekle.
            highPrice = posCurrentPrice.p;
            openPos.topNeedle = posCurrentPrice.p;
            updatedHighPricePos.push(openPos);
            // topNeedle değişimde sadece windowdakileri güncellersem nolur?
          }
  
          if(highPrice >= openPrice * 1.10) {
            if(closePrice <= highPrice * 0.96) {
              console.log('bot close (X-%4) Profit', posCurrentPrice.s);
              // CLOSE POSITION (%x-4 Profit)
              openPos.closeType = 'B (x-4%)';
              updatedClosePos.push(openPos);
            }
          }           
          else if(highPrice >= openPrice * 1.03) {
            if(closePrice <= highPrice * 0.97) {
              console.log('bot close (X-%3) Profit', posCurrentPrice.s);
              // CLOSE POSITION (%x-3 Profit)
              openPos.closeType = 'B (x-3%)';
              updatedClosePos.push(openPos);
            }
          }
          else if(highPrice >= openPrice * 1.01) {
            if(closePrice <= openPrice) {
              console.log('bot close %0 Notr', posCurrentPrice.s);
              // CLOSE POSITION (%0 Notr)
              openPos.closeType = 'B (0%)';
              updatedClosePos.push(openPos);
            }
          }
          else if(closePrice <= openPrice * 0.970) {
            //CLOSE POSITION (-%2.5 Loss)
            console.log('bot close -%3 Loss', posCurrentPrice.s);
            openPos.closeType = 'B (-3%)';
            updatedClosePos.push(openPos);
          }
  
          
            //let forceUpdate = forceClosePosition(openPosition, socketMessage[1], state.kLinesList,'-%3');
            //dispatch({type: "FORCE_UPDATE_KLINES_LIST", payload: forceUpdate});
          
        });
  
        if(updatedHighPricePos.length) {
          // açık pozlar içinde değişen high price var ise,
          // sadece window.openPositions'daki açık pozlar güncellenecek.
          updatedHighPricePos.map(pos => {
            console.log('higprice update symbol', pos.symbol);
            window.openPositions[pos.symbol] = pos;
          });
          console.log('high price güncellenmiş open positions', window.openPositions, updatedHighPricePos.length);
        }
  
        if(updatedClosePos.length) {
          // kapanması gereken pozlar var ise,
          // topluca forceClose'a gönderilip gerekli atamalar yapıldıktan sonra,
          // klinesList, allPositions güncellemeleri yapılacak.
          forceClosePositions(updatedClosePos);
        }
      }

*/



    }
  }, [currentPriceTrackingCandlesticks, state.symbols, state.openPositions]);

  // BURASI YENİ SOCKETE GÖRE DÜZENLENİP SİLİNECEK
  useEffect(() => {
/*
    if(Object.keys(window.openPositions).length > 0) {
      console.log(window.openPositions);
      const updatedHighPricePos = [];
      const updatedClosePos = [];

      Object.keys(window.openPositions).map(pos => {
        const openPos = window.openPositions[pos];
        const posCurrentPrice = markPriceSocketMessage.find(m => m.s === openPos.symbol);
        //console.log(markPriceSocketMessage, posCurrentPrice);

        let openPrice = openPos.openPrice;
        let closePrice = posCurrentPrice.p;
        let highPrice = openPos.topNeedle;

        if(openPos.topNeedle < posCurrentPrice.p) {
          // close price topNeedle'dan büyükse highPrice'ı setle, pozisyon içindeki topNeedle değerini güncelle
          // updatedHighPricePos arrayine güncellenen pozisyonu ekle.
          highPrice = posCurrentPrice.p;
          openPos.topNeedle = posCurrentPrice.p;
          updatedHighPricePos.push(openPos);
          // topNeedle değişimde sadece windowdakileri güncellersem nolur?
        }

        if(highPrice >= openPrice * 1.10) {
          if(closePrice <= highPrice * 0.96) {
            console.log('bot close (X-%4) Profit', posCurrentPrice.s);
            // CLOSE POSITION (%x-4 Profit)
            openPos.closeType = 'B (x-4%)';
            updatedClosePos.push(openPos);
          }
        }           
        else if(highPrice >= openPrice * 1.03) {
          if(closePrice <= highPrice * 0.97) {
            console.log('bot close (X-%3) Profit', posCurrentPrice.s);
            // CLOSE POSITION (%x-3 Profit)
            openPos.closeType = 'B (x-3%)';
            updatedClosePos.push(openPos);
          }
        }
        else if(highPrice >= openPrice * 1.01) {
          if(closePrice <= openPrice) {
            console.log('bot close %0 Notr', posCurrentPrice.s);
            // CLOSE POSITION (%0 Notr)
            openPos.closeType = 'B (0%)';
            updatedClosePos.push(openPos);
          }
        }
        else if(closePrice <= openPrice * 0.970) {
          //CLOSE POSITION (-%2.5 Loss)
          console.log('bot close -%3 Loss', posCurrentPrice.s);
          openPos.closeType = 'B (-3%)';
          updatedClosePos.push(openPos);
        }
      });

      if(updatedHighPricePos.length) {
        // açık pozlar içinde değişen high price var ise,
        // sadece window.openPositions'daki açık pozlar güncellenecek.
        updatedHighPricePos.map(pos => {
          console.log('higprice update symbol', pos.symbol);
          window.openPositions[pos.symbol] = pos;
        });
        console.log('high price güncellenmiş open positions', window.openPositions, updatedHighPricePos.length);
      }

      if(updatedClosePos.length) {
        // kapanması gereken pozlar var ise,
        // topluca forceClose'a gönderilip gerekli atamalar yapıldıktan sonra,
        // klinesList, allPositions güncellemeleri yapılacak.
        forceClosePositions(updatedClosePos);
      }
    }
    */
  }, [markPriceSocketMessage]);

  useEffect(() => {
    //console.log('APP USEEFFECT');
    const coinSocket = new Websocket();
    const priceTrackingSocket = new Websocket();

    if(state.startListenSocket) {
      let priceTrackingSocketUrl;
      let socketUrl;
        if(state.marketType === "FUTURES") {
          socketUrl = createCandleStickSocketUrl("FUTURES", state.symbols, state.analysisOptions.timePeriod);
          //priceTrackingSocketUrl = createCandleStickSocketUrl("FUTURES", state.symbols, '1m');
        }
        else {
          socketUrl = createCandleStickSocketUrl("SPOT", state.symbols, state.analysisOptions.timePeriod);
        }
        //console.log('START LISTEN SOCKET');
        coinSocket.open(socketUrl, listenMessage);
        //priceTrackingSocket.open(priceTrackingSocketUrl, listenPriceTrackingMessage);
    }

    return () => { 
      if(coinSocket.isSocketInitialized()) {
        coinSocket.close();
      }
      if(priceTrackingSocket.isSocketInitialized()) {
        priceTrackingSocket.close();
      }
    }
  }, [state.startListenSocket, listenMessage, listenPriceTrackingMessage, state.symbols, state.marketType]);

  const changeAnalysisModel = () => {
    //console.log('Change Analysis Model');
    dispatch({type: "SHOW_ANALYSIS_MODAL", payload: true});
  } 

  useEffect(() => {
    console.log('Yeni Değişen Model', state.analysisModel);
    dispatch({type: "SHOW_ANALYSIS_MODAL", payload: false});

    if(analysisModelRef.current === state.analysisModel) {
      return;
    }
    
    analysisModelRef.current = state.analysisModel;

    if(Object.keys(state.kLinesList).length !== 0) {
      setIsLoading(true);
      setTimeout(() => {
        allCurrencyUpdateNewModelAnalysis(state.kLinesList, state.analysisModel, state.supports).then(data => {
          //console.log('Then içinden gelen data', data);
          dispatch({type: "CHANGE_KLINES_LIST", payload: data});
          setIsLoading(false)
          //console.log(data);
        });
      }, 1000);
    }
  }, [state.analysisModel, dispatch, state.kLinesList, state.supports]);

  return (
    <>
      <div className="App">
        <Layout className='layout'>
          <Layout.Header>
            <Header onChangeModel={changeAnalysisModel} showDrawer={showDrawer} />
          </Layout.Header>
          <Layout.Content>
            {isLoading && <AnalysisLoading spin={true} />}
            <div className='site-content'>
              <Router />
            </div> 
          </Layout.Content>
          <Layout.Footer>
            <Footer />
          </Layout.Footer>
          {/*<InfoModal />*/}
          <CalculatorModal />
          {/*<AnalysisModelModal />*/}
        </Layout>
        <MobileDrawer onClose={onClose} open={open} />
      </div>
    </>

  );
}

export default AuthApp;
