import React from 'react';
import {Routes, Route} from 'react-router-dom';
import Home from '../pages/Home';
import Calculator from '../pages/Calculator';
import Analysis from '../pages/Analysis';
import Currencies from '../pages/Currencies';
import FuturesDetails from '../pages/Currencies/FuturesDetails';
import SpotDetails from '../pages/Currencies/SpotDetails';
import Details from '../pages/Currencies/Details';
import ProfitLossStats from '../pages/ProfitLossStats';
import Simulator from '../pages/Simulator';
import AI from '../pages/AI';
import SpotBalance from '../pages/SpotBalance';
import Wallet from '../pages/Wallet';
import Cumulative from '../pages/Cumulative';
import Accounts from '../pages/Accounts';

const Router = () => {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/analysis" element={<Analysis />} />
            <Route path="/currencies" element={<Currencies />} />
            <Route path="/currencies/details/:id" element={<Details />} />
            <Route path="/futures-currencies/:id" element={<FuturesDetails />} />
            <Route path="/spot-currencies/:id" element={<SpotDetails />} />
            <Route path="/calculator" element={<Calculator />} />
            <Route path="/all-pnl" element={<ProfitLossStats />} />
            <Route path="/simulator" element={<Simulator />} />
            <Route path="/spot-balance" element={<SpotBalance />} />
            <Route path="/wallet" element={<Wallet />} />
            <Route path="/cumulative" element={<Cumulative />} />
            <Route path="/accounts" element={<Accounts />} />


        </Routes>
    );
};

export default Router;