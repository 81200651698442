import React, {useContext} from 'react';
import './style.scss';
import {Link} from 'react-router-dom';
import {Col} from 'antd';
import { StarFilled} from '@ant-design/icons';
import { Context } from '../../store';

const CoinListBox = ({coin}) => {
    const {state} = useContext(Context);

    return (
        <Col xs={12} md={4}>
            <Link to={`/currencies/details/${coin.baseAsset.toLowerCase()}`} state={{uniqId: coin.id}}>
                <div className='coin-list-box'>
                    {/*
                    <span className={`risk ${coin.riskLevel}`}>
                        {coin.riskLevel === "star" && <StarFilled style={{color: '#17a5f9', fontSize: 20}}/>}
                    </span>                    
                    */}
                    <img src={`${process.env.PUBLIC_URL}/images/crypto-logo/${coin.iconUrl}`} alt="" />
                    <p className='symbol-name'><strong>{coin.baseAsset}</strong></p>
                    <p className='full-name'>({coin.name})</p>
                </div>          
            </Link>
        </Col>
    );
};

export default CoinListBox;