import { getRequestApi } from "../libs/request";

const headers = {
    "X-MBX-APIKEY": process.env.REACT_APP_BINANCE_API_KEY,
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Credentials": true
    //"Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS"
}

const getTickerPrice = async (symbols) => {
    //console.log('getPriceTicker symbols ', symbols);
    let url = `${process.env.REACT_APP_BINANCE_SPOT_API_BASE_URL}/ticker/price?symbols=${symbols}`;

    return getRequestApi(url);
}

const getFuturesKlines = async (symbol, interval, limit = 1) => {
    let url = `${process.env.REACT_APP_BINANCE_FUTURES_API_BASE_URL}/klines?symbol=${symbol}&interval=${interval}&limit=${limit}`;
    return getRequestApi(url, {}, headers);
}

const getFuturesKlinesWithDate = async (symbol, interval, limit = 1, startTime, endTime = Date.now()) => {
    let url = `${process.env.REACT_APP_BINANCE_FUTURES_API_BASE_URL}/klines?symbol=${symbol}&interval=${interval}&limit=${limit}&startTime=${startTime}&endTime=${endTime}`;
    return getRequestApi(url, {}, headers);
}

const getSpotKlines = async (symbol, interval, limit = 1) => {
    let url = `${process.env.REACT_APP_BINANCE_SPOT_API_BASE_URL}/klines?symbol=${symbol}&interval=${interval}&limit=${limit}`;
    return getRequestApi(url);
}

const getSpotKlinesWithDate = async (symbol, interval, limit = 1, startTime, endTime = Date.now()) => {
    let url = `${process.env.REACT_APP_BINANCE_SPOT_API_BASE_URL}/klines?symbol=${symbol}&interval=${interval}&limit=${limit}&startTime=${startTime}&endTime=${endTime}`;
    return getRequestApi(url);
}

export const binanceService = {
    getTickerPrice,
    getFuturesKlines,
    getFuturesKlinesWithDate,
    getSpotKlines,
    getSpotKlinesWithDate
};
